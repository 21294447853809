import { v4 as uuid } from "uuid";
import { fields } from "@monorepo/utils/src/variables/projectsData/akView/fields";
import { authorities } from "@monorepo/utils/src/authorities/authorities";

export const modalFields = {
  AK_COMMON_NAME: "commonName",
  AK_ORGANIZATION_NAME: "organizationName",
  AK_EP_SER_NUM: "serNum",
  AK_EP_EXPIRED_AT: "expiredAt",
  AK_EP_SIGN_TIME: "signTime",
  AK_EP_TIME_STAMP: "timeStamp",
  AK_EP_TS_SER_NUM: "tsSerNum",
};

export const modalElements = [
  {
    id: uuid(),
    isEdited: false,
    title: "Идентификатор",
    tooltip: "Идентификатор АК в ЦХЭД",
    className: "info-modal__element_title",
    value: fields.ID,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Дата создания",
    tooltip: "Дата создания АК",
    className: "info-modal__element_title",
    value: fields.AK_CREATE_DATE,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Дата проверки",
    tooltip: "Дата проверки целостности АК в хранилище данных Уполномоченного органа",
    className: "info-modal__element_title",
    value: fields.AK_CHECK_DATE,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Дата продления юридической значимости",
    tooltip: "Дата, до которой необходимо провести процедуру продления юридической значимости",
    className: "info-modal__element_title",
    value: fields.AK_LEGAL_RENEWAL_DATE,
  },
  {
    id: uuid(),
    isEdited: false,
    link: true,
    title: "Полное наименование ОИК",
    isLink: true,
    linkRights: [authorities.OIK_LIST],
    tooltip: "Полное наименование ОИК, из которого был получен ТК",
    className: "info-modal__element_full-size",
    value: fields.OIK_NAME,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Идентификатор ТК",
    isLink: true,
    linkRights: [authorities.TK_DOCUMENTS_LIST, authorities.TK_DICTIONARIES_LIST, authorities.TK_OTHER_LIST],
    tooltip: "Идентификатор ТК из которого сформирован АК в ЦХЭД",
    className: "info-modal__element_title",
    value: fields.TK_ID,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Уникальный идентификатор ТК",
    isLink: true,
    linkRights: [authorities.TK_DOCUMENTS_LIST, authorities.TK_DICTIONARIES_LIST, authorities.TK_OTHER_LIST],
    tooltip: "Уникальный идентификатор ТК из которого сформирован АК в ЦХЭД",
    className: "info-modal__element_title",
    value: fields.TK_GUID,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Статус",
    tooltip:
      "Статус АК может принимать значения: Сформирован, Размещён на хранение, Требует переподписания, Требуется продление юридической значимости, Ошибка продления юридической значимости",
    className: "info-modal__element",
    value: fields.STATUS,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Тип документа в АК",
    tooltip: "Тип документа, содержащегося в АК. Может принимать значения: Опись, ЭАД",
    className: "info-modal__element",
    value: fields.AK_DOC_TYPE,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Идентификатор документа в АК",
    tooltip: "Идентификатор документа, содержащегося в АК в ЦХЭД",
    className: "info-modal__element_title",
    value: fields.ID_DOC,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Заголовок документа в АК",
    tooltip: "Заголовок документа, содержащегося в АК",
    className: "info-modal__element_title",
    value: fields.AK_DOC_TITLE,
  },
];

export const signatureElements = [
  {
    id: uuid(),
    isEdited: false,
    title: "Кому выдан",
    tooltip: "Фамилия имя и отчество владельца сертификата",
    className: "info-modal__element_title",
    value: modalFields.AK_COMMON_NAME,
  },
  {
    id: uuid(),
    isEdited: false,
    tooltip: "Наименование юридического лица АУЦ, выдавшего сертификат	",
    title: "Кем выдан",
    className: "info-modal__element_title",
    value: modalFields.AK_ORGANIZATION_NAME,
  },
  {
    id: uuid(),
    isEdited: false,
    tooltip: "Серийный номер сертификата",
    title: "Серийный номер",
    className: "info-modal__element_title",
    value: modalFields.AK_EP_SER_NUM,
  },
  {
    id: uuid(),
    isEdited: false,
    tooltip: "Дата окончания действия сертификата",
    title: "Действителен",
    className: "info-modal__element",
    value: modalFields.AK_EP_EXPIRED_AT,
  },
  {
    id: uuid(),
    isEdited: false,
    tooltip: "Дата подписания файла электронной подписью",
    title: "Дата подписания",
    className: "info-modal__element",
    value: modalFields.AK_EP_SIGN_TIME,
  },
  {
    id: uuid(),
    isEdited: false,
    tooltip: "Штамп времени",
    title: "Штамп времени",
    className: "info-modal__element_title",
    value: modalFields.AK_EP_TIME_STAMP,
  },
  {
    id: uuid(),
    isEdited: false,
    tooltip: "Серийный номер сервера штампа времени",
    title: "Серийный номер сервера штампа времени",
    className: "info-modal__element_title",
    value: modalFields.AK_EP_TS_SER_NUM,
  },
];

export const fullModalElements = [...modalElements];
