import { IAKElement } from "@monorepo/inventory/src/views/AKView/types/element";
import { fields } from "@monorepo/utils/src/variables/projectsData/akView/fields";
import moment from "moment";

export const convertApiItemToUi = (element: IAKElement): IAKElement => ({
  ...element,
  [fields.ID]: element.id,
  [fields.AK_CREATE_DATE]: element.creationDate ? moment(element.creationDate).format("YYYY-MM-DD") : "",
  [fields.AK_CHECK_DATE]: element.receipt?.lastCheckDate ? moment(element.receipt?.lastCheckDate).format("YYYY-MM-DD") : "",
  [fields.AK_LEGAL_RENEWAL_DATE]: element.receipt?.legalRenewalDate ? moment(element.receipt?.legalRenewalDate).format("YYYY-MM-DD") : "",
  [fields.STATUS]: element?.status ?? "",
  [fields.OIK_NAME]: element.oikName ?? "",
  [fields.TK_ID]: element.transportContainerId ?? "",
  [fields.TK_GUID]: element.transportContainerGuid ?? "",
  [fields.AK_DOC_TYPE]: element.transportContainerType ?? "",
  [fields.ID_DOC]: element.documentId ?? "-",
  [fields.AK_DOC_TITLE]: element.documentTitle ?? "-",
  [fields.RECEIPT_GUID]: element.receipt?.storageGuid ?? "-",
  [fields.RECEIPT_ID]: element.receipt?.id ?? "-",
  [fields.HDUO_ID]: element.hduoGuid ?? "-",
  [fields.AUDITS]: element.audits || [],
});
