
































































































import { defineComponent } from "@vue/composition-api";
import CardModalInfoElement from "@monorepo/uikit/src/components/tableCardModal/CardModalInfoElement.vue";
import CardModalStatusChip from "@monorepo/uikit/src/components/tableCardModal/CardModalStatusChip.vue";
import { mapActions, mapGetters } from "vuex";
import { IAKElement } from "@monorepo/inventory/src/views/AKView/types/element";
import eventBus from "@monorepo/utils/src/eventBus";
import { MODAL_EVENT_BUS_ACTIONS } from "@monorepo/utils/src/eventBus/events/modal";
import { formModalPayload } from "@monorepo/utils/src/eventBus/utils/formModalPayload";
import { modalElements, signatureElements } from "@monorepo/utils/src/variables/projectsData/akView/modalElements";
import { viewUniqKey } from "@monorepo/utils/src/variables/projectsData/akView/viewTitle";
import { fields } from "@monorepo/utils/src/variables/projectsData/akView/fields";
import { convertApiItemToUi } from "@monorepo/inventory/src/views/AKView/utils/convertApiItemToUi";
import CardModalToggleViewSize from "@monorepo/uikit/src/components/tableCardModal/CardModalToggleViewSize.vue";
import CardModalToggleTableItems from "@monorepo/uikit/src/components/tableCardModal/CardModalToggleTableItems.vue";
import { getFullPath } from "@monorepo/utils/src/api/utils";
import { QUERY_PATH } from "@monorepo/utils/src/api/queryPath";
import { authorities } from "@monorepo/utils/src/authorities/authorities";
import { isAuthorityExist } from "@monorepo/utils/src/utils/isAuthorityExist";
import { Sections } from "@monorepo/utils/src/types/cellsSections";
import ExportBtn from "@monorepo/uikit/src/components/tableViews/ExportBtn.vue";
import { CardMode } from "@monorepo/utils/src/types/cardMode";
import { Route } from "vue-router";
import InfSecurityTable from "./InfSecurityTable.vue";
import moment, { Moment } from "moment";
import { showNotification } from "@monorepo/utils/src/eventBus/utils/showNotification";
import { NOTIFICATION_STATUS } from "@monorepo/utils/src/eventBus/types/notification";
import CardModalChapter from "@monorepo/uikit/src/components/tableCardModal/CardModalChapter.vue";
import useModalChangeByKeypress from "@monorepo/utils/src/composables/useModalChangeByKeypress";
import useUniqueLinkModal from "@monorepo/utils/src/composables/useUniqueLinkModal";
import useFormLinkByAuthoritiesModal from "@monorepo/utils/src/composables/useFormLinkByAuthoritiesModal";
import { ModalType } from "@monorepo/utils/src/variables/modalType";

export default defineComponent({
  name: "AKInfoModal",
  components: {
    CardModalInfoElement,
    CardModalStatusChip,
    CardModalToggleViewSize,
    CardModalToggleTableItems,
    ExportBtn,
    InfSecurityTable,
    CardModalChapter,
  },
  props: {
    type: {
      type: String,
      default: ModalType.EDIT,
    },
  },
  data() {
    return {
      modalElements,
      signatureElements,
      viewUniqKey,
      openedPanels: [0],
      fields,
      section: Sections.AK,
      cardMode: CardMode,
      element: {} as ReturnType<typeof convertApiItemToUi>,
      isLoading: false,
      isLoadingReasign: false,
    };
  },
  watch: {
    openedId: {
      immediate: true,
      async handler(
        this: {
          $route: Route;
          addQueryOpenedId: () => void;
          getCard: () => void;
          isLoading: boolean;
          isShowAnimation: boolean;
        },
        value
      ) {
        if (value) {
          this.isLoading = true;
          await this.addQueryOpenedId();
          this.getCard();
        }
      },
    },
  },
  computed: {
    ...mapGetters("auth", ["user", "isShowAnimation", "cardModeList"]),
    ...mapGetters("akView", ["data", "openedId"]),
    ...mapGetters("app", ["isLoadingAk"]),
    cardModeResult(): CardMode {
      return this.cardModeList[this.section as string] || CardMode.DEFAULT;
    },
    isShowExport(): boolean {
      return isAuthorityExist(this.user, authorities.AK_EXPORT);
    },
    isShowReasign(): boolean {
      return isAuthorityExist(this.user, authorities.AK_RESIGN);
    },
    isShowInfSecurity(): boolean {
      return isAuthorityExist(this.user, authorities.INFORMATION_SECURITY_JOURNAL_LIST);
    },
    isShowDownloadBtn(): boolean {
      return isAuthorityExist(this.user, authorities.AK_DOWNLOAD);
    },
    status(): string {
      return this.element?.status?.toString().toLowerCase() || "";
    },
    statusColor(): string {
      switch (this.status) {
        case "сформирован":
        case "размещён на хранение":
          return "#00A459";
        case "требует переподписания":
        case "требуется продление юридической значимости":
        case "ошибка продления юридической значимости":
          return "#D34039";
        default:
          return "";
      }
    },
    isLoadingDownload(): boolean {
      return this.isLoadingAk.indexOf(this.element.hduoId) !== -1;
    },
  },
  methods: {
    ...mapActions("app", ["openNewWindow"]),
    ...mapActions("akView", [
      "getSignatures",
      "changeOpenedId",
      "getExportElementData",
      "getAKElement",
      "renewalAKSignatureQuery",
      "recognizeEadContent",
    ]),
    clickElementCb(header: { isLink: boolean; value: string }) {
      switch (header.value) {
        case fields.TK_ID:
          (
            this as unknown as { moveByAuthorities: (header: { isLink: boolean }, path: string, query: Record<string, unknown>) => void }
          ).moveByAuthorities(header, "/inventory/tk", {
            id: this.element[fields.TK_ID as keyof ReturnType<typeof convertApiItemToUi>] as string,
            isOpenModal: "1",
          });
          break;
        case fields.TK_GUID:
          (
            this as unknown as { moveByAuthorities: (header: { isLink: boolean }, path: string, query: Record<string, unknown>) => void }
          ).moveByAuthorities(header, "/inventory/tk", {
            guid: this.element[fields.TK_GUID as keyof ReturnType<typeof convertApiItemToUi>] as string,
            isOpenModal: "1",
          });
          break;
        case fields.OIK_NAME:
          (
            this as unknown as { moveByAuthorities: (header: { isLink: boolean }, path: string, query: Record<string, unknown>) => void }
          ).moveByAuthorities(header, "/dictionaries/oik", {
            name: this.element[fields.OIK_NAME as keyof ReturnType<typeof convertApiItemToUi>] as string,
            id: this.element[fields.OIK_ID as keyof ReturnType<typeof convertApiItemToUi>] as string,
            isOpenModal: "1",
          });
          break;
        default:
          break;
      }
    },
    async getCard() {
      this.getAKElement(this.openedId)
        .then(async (data: IAKElement) => {
          this.element = convertApiItemToUi(data || {});
          this.element.audits = [
            {
              eventTime: moment().utcOffset(3),
              initiator: this.user?.details?.username,
            } as { eventTime: Moment; initiator: string },
          ].concat(this.element.audits);
          const signatures = await this.getSignatures(data.digitalSignatureGuid);
          this.element = {
            ...this.element,
            ...signatures,
            expiredAt: signatures.expiredAt ? moment(signatures.expiredAt).format("YYYY-MM-DD HH:mm") : "",
            signTime: signatures.signTime ? moment(signatures.signTime).format("YYYY-MM-DD HH:mm") : "",
          };
        })
        .catch(console.error)
        .finally(() => {
          this.isLoading = false;
        });
    },
    async renewalAKSignature() {
      this.isLoadingReasign = true;
      const isSaved = await this.renewalAKSignatureQuery(this.element[fields.RECEIPT_ID as keyof ReturnType<typeof convertApiItemToUi>]);
      this.isLoadingReasign = false;
      if (isSaved) {
        showNotification("Продление юридической значимости выполнено успешно", NOTIFICATION_STATUS.SUCCESS);
        this.getCard();
      }
    },
    openQuestionModal() {
      eventBus.$emit(
        MODAL_EVENT_BUS_ACTIONS.TOGGLE_MODAL,
        formModalPayload(true, "DeleteModal", {
          title: "Вы точно хотите продлить юридическую значимость?",
          icon: "mdi-comment-question-outline",
          yesBtnTitle: "Да, продлить",
          cbOnDelete: this.renewalAKSignature,
        })
      );
    },
    openDownload(id: string | number) {
      this.openNewWindow(`${getFullPath(QUERY_PATH.GET_HDUO_DOWNLOAD)}?id=${id}`);
    },
    openDownloadReceipt(id: string | number) {
      this.openNewWindow(`${getFullPath(QUERY_PATH.GET_RECEIPT_DOWNLOAD)}?uuid=${id}&origin=true`);
    },
    closeModal() {
      this.$emit("close");
    },
  },
  setup(props, context) {
    const { root } = context;
    useModalChangeByKeypress(root, "akView", Sections.AK, props.type);
    const { addQueryOpenedId } = useUniqueLinkModal(root, "akView");
    const { resultModalElements, moveByAuthorities } = useFormLinkByAuthoritiesModal(root, modalElements);

    return {
      resultModalElements,
      addQueryOpenedId,
      moveByAuthorities,
    };
  },
});
