import { ComponentInstance, onBeforeMount } from "@vue/composition-api";

const useGetTableLibs = (root: ComponentInstance): void => {
  const store = root.$store;

  onBeforeMount(() => {
    store.dispatch("akView/getOiks");
    store.dispatch("akView/getStatuses");
    store.dispatch("akView/getTypes");
  });
};

export default useGetTableLibs;
