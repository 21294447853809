import { ITableFiltersObject } from "@monorepo/utils/src/store/types/tableFiltersObject";
import { convertAutocompleteItemToApi, formDateFilter } from "@monorepo/utils/src/api/convertFiltersToApi";
import { fields } from "@monorepo/utils/src/variables/projectsData/akView/fields";

export const convertFiltersCustomToApi = (resultFilters: ITableFiltersObject): void => {
  formDateFilter(resultFilters, "creationDate");
  formDateFilter(resultFilters, "checkDate");
  formDateFilter(resultFilters, "legalRenewalDate");

  convertAutocompleteItemToApi(resultFilters, fields.OIK_NAME, `fieldFilters.${fields.OIK_NAME}.name`);

  if (resultFilters.sort?.["lastCheckDate"]) {
    resultFilters.sort["receipt.lastCheckDate"] = resultFilters.sort["lastCheckDate"];
    delete resultFilters.sort["lastCheckDate"];
  }

  if (resultFilters.sort?.["legalRenewalDate"]) {
    resultFilters.sort["receipt.legalRenewalDate"] = resultFilters.sort["legalRenewalDate"];
    delete resultFilters.sort["legalRenewalDate"];
  }
};
