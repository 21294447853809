export interface IAKFields {
  ID: string;
  AK_CREATE_DATE: string;
  AK_CHECK_DATE: string;
  AK_LEGAL_RENEWAL_DATE: string;
  STATUS: string;
  AK_STATUS: string;
  OIK_NAME: string;
  OIK_ID: string;
  TK_ID: string;
  TK_GUID: string;
  AK_DOC_TYPE: string;
  AUDITS: string;
  DOC_TYPE: string;
  ID_DOC: string;
  AK_DOC_TITLE: string;
  CREATE_DATE: string;
  CREATE_DATE_TO: string;
  CREATE_DATE_FROM: string;
  LEGAL_RENEWAL_DATE: string;
  LEGAL_RENEWAL_DATE_TO: string;
  LEGAL_RENEWAL_DATE_FROM: string;
  CHECK_DATE: string;
  CHECK_DATE_TO: string;
  CHECK_DATE_FROM: string;
  HDUO_ID: string;
  RECEIPT_ID: string;
  RECEIPT_GUID: string;
  ID_TK: string;
  DOC_ID: string;

  INF_SECURITY_TABLE_DATA: string;
  INF_SECURITY_TABLE_USER: string;
  INF_SECURITY_TABLE_IP: string;
}

export const fields: IAKFields = {
  ID: "id",
  AK_CREATE_DATE: "creationDate",
  AK_CHECK_DATE: "lastCheckDate",
  AK_LEGAL_RENEWAL_DATE: "legalRenewalDate",
  AUDITS: "audits",
  STATUS: "status",
  AK_STATUS: "akStatus",
  OIK_NAME: "oikName",
  OIK_ID: "oikId",
  TK_ID: "transportContainerId",
  TK_GUID: "transportContainerGuid",
  AK_DOC_TYPE: "documentType",
  DOC_TYPE: "transportContainerType",
  ID_DOC: "documentId",
  AK_DOC_TITLE: "akDocTitle",
  CREATE_DATE: "creationDate",
  CREATE_DATE_TO: "creationDateTo",
  CREATE_DATE_FROM: "creationDateFrom",
  LEGAL_RENEWAL_DATE: "legalRenewalDate",
  LEGAL_RENEWAL_DATE_TO: "legalRenewalDateTo",
  LEGAL_RENEWAL_DATE_FROM: "legalRenewalDateFrom",
  CHECK_DATE: "checkDate",
  CHECK_DATE_TO: "checkDateTo",
  CHECK_DATE_FROM: "checkDateFrom",
  HDUO_ID: "hduoId",
  RECEIPT_ID: "receiptId",
  RECEIPT_GUID: "receiptGUID",
  ID_TK: "tkId",
  DOC_ID: "idDoc",

  INF_SECURITY_TABLE_DATA: "tmp1",
  INF_SECURITY_TABLE_USER: "tmp2",
  INF_SECURITY_TABLE_IP: "tmp3",
};
