

















































import { defineComponent, PropType } from "@vue/composition-api";
import { fields } from "@monorepo/utils/src/variables/projectsData/akView/fields";
import moment from "moment";

type IResultData = { [key: string]: string | number };

const tableHeaders = [
  { width: 160, value: fields.INF_SECURITY_TABLE_DATA, text: "Дата", tooltip: "Дата" },
  { width: 160, value: fields.INF_SECURITY_TABLE_USER, text: "Пользователь", tooltip: "Пользователь" },
  { width: 160, value: fields.INF_SECURITY_TABLE_IP, text: "IP адрес", tooltip: "IP адрес" },
];

type itemElement = {
  systemName: string;
  systemVersion: string;
  initiator: string;
  initiatorIp: string;
  eventCode: null;
  eventType: string;
  eventTime: string;
  message: string;
  objectId: number;
  objectTypeCode: null;
};

export default defineComponent({
  name: "InfSecurityTable",
  data() {
    return {
      tableHeaders,
    };
  },
  props: {
    items: {
      type: Array as PropType<itemElement[]>,
      default() {
        return [];
      },
    },
  },
  computed: {
    resultData(): IResultData[] {
      return this.items.map((item) => ({
        id: item.objectId,
        [fields.INF_SECURITY_TABLE_DATA]: item.eventTime ? moment(item.eventTime).format("YYYY-MM-DD HH:mm") : "-",
        [fields.INF_SECURITY_TABLE_USER]: item.initiator || "-",
        [fields.INF_SECURITY_TABLE_IP]: item.initiatorIp || "-",
      }));
    },
  },
});
