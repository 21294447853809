import { v4 as uuid } from "uuid";
import { fields } from "./fields";
import { authorities } from "@monorepo/utils/src/authorities/authorities";
import { headerCheckboxObject } from "@monorepo/utils/src/variables/headerCheckboxValue";

export const tableHeaders = [
  headerCheckboxObject(),
  {
    text: "Идентификатор",
    tooltip: "Идентификатор АК в ЦХЭД",
    defaultWidth: 142,
    value: fields.ID,
    isSorted: true,
    isHandle: true,
    id: uuid(),
    isHiddenByDefault: true,
  },
  {
    text: "Дата создания",
    tooltip: "Дата создания АК",
    defaultWidth: 130,
    value: fields.AK_CREATE_DATE,
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Дата проверки",
    tooltip: "Дата проверки целостности АК в хранилище данных Уполномоченного органа",
    defaultWidth: 130,
    value: fields.AK_CHECK_DATE,
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Дата продления юридической значимости",
    tooltip: "Дата, до которой необходимо провести процедуру продления юридической значимости",
    defaultWidth: 230,
    value: fields.AK_LEGAL_RENEWAL_DATE,
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Статус",
    tooltip:
      "Статус АК может принимать значения: Сформирован, Размещён на хранение, Требует переподписания, Требуется продление юридической" +
      " значимости, Ошибка продления юридической значимости",
    defaultWidth: 220,
    value: fields.STATUS,
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Наименование ОИК",
    tooltip: "Полное наименование ОИК, из которого был получен ТК",
    isLink: true,
    linkRights: [authorities.OIK_LIST],
    defaultWidth: 230,
    value: fields.OIK_NAME,
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Идентификатор ТК",
    tooltip: "Идентификатор ТК из которого сформирован АК в ЦХЭД",
    defaultWidth: 200,
    value: fields.TK_ID,
    isLink: true,
    linkRights: [authorities.TK_DOCUMENTS_LIST, authorities.TK_DICTIONARIES_LIST, authorities.TK_OTHER_LIST],
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Тип документа в АК",
    tooltip: "Тип документа, содержащегося в АК. Может принимать значения: Опись, ЭАД",
    defaultWidth: 130,
    value: fields.AK_DOC_TYPE,
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Идентификатор документа в АК",
    isLink: false,
    tooltip: "Идентификатор документа, содержащегося в АК в ЦХЭД",
    defaultWidth: 200,
    value: fields.ID_DOC,
    isSorted: true,
    isHandle: true,
    id: uuid(),
    isHiddenByDefault: true,
  },
  {
    text: "Заголовок документа в АК",
    tooltip: "Заголовок документа, содержащегося в АК",
    defaultWidth: 300,
    value: fields.AK_DOC_TITLE,
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
];
