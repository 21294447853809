import { fields } from "@monorepo/utils/src/variables/projectsData/akView/fields";

export const currentFilters = (): Record<string, unknown> => ({
  [fields.ID]: "",
  [fields.OIK_NAME]: {},
  [fields.CREATE_DATE_FROM]: "",
  [fields.CREATE_DATE_TO]: "",
  [fields.LEGAL_RENEWAL_DATE_FROM]: "",
  [fields.LEGAL_RENEWAL_DATE_TO]: "",
  [fields.CHECK_DATE_FROM]: "",
  [fields.CHECK_DATE_TO]: "",
  [fields.AK_STATUS]: [],
  [fields.ID_TK]: "",
  [fields.DOC_TYPE]: [],
  [fields.DOC_ID]: "",
});
